<template>
    <section>
        <NavigationGoBack />
        <div class="space-between mt-1">
            <h1>Alle Services ({{ serviceStore.totalServices }})</h1>
            <NuxtLink
                role="button"
                :to="localePath('dashboard-services-new')"
            >
                Neues Service erstellen
            </NuxtLink>
        </div>
        <ServiceGridWrapper
            class="center mt-2"
            :services-wrapper-small="false"
        >
            <template #content>
                <MichaelCard />
                <ServiceSearchCard
                    v-for="service in serviceStore.services"
                    :key="service.id"
                    :service="service"
                />
            </template>
        </ServiceGridWrapper>
        <button
            v-if="serviceStore.hasNext"
            class="mt-1 block-link"
            :aria-busy="loading"
            @click="fetchMoreServices"
        >
            Mehr laden
        </button>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const serviceStore = useServiceStore()

const { business } = storeToRefs(useBusinessStore())

const toast = useToast()
const loading = ref(false)
async function fetchMoreServices () {
    loading.value = true
    try {
        await serviceStore.getServices(business.value.id)
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}

useHead({
    title: 'Deine Services'
})
</script>

<style scoped>
h1 { margin-bottom: 0; }
.space-between {
    flex-wrap: wrap;
    column-gap: var(--spacing);
}
.center { margin-left: auto; margin-right: auto; }
</style>
